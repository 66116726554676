import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ToastrService} from 'ngx-toastr';
import {take, takeWhile} from 'rxjs/operators';

import {AppSettings} from '../../../app.settings';
import {Settings} from '../../../models/settings.model';
import {MenuService} from '../menu/menu.service';
import {AuthService} from '../../../services/auth.service';
import {ProductService} from '../../../services/product.service';
import {Product} from '../../../models/product.model';
import {ProductCatalog} from '../../../models/product-catalog.model';
import {NotificationService} from '../../../services/notification.service';
import {ProfileService} from '../../../services/profile.service';
import {ConfigService} from '../../../services/config.service';
import {ContentService} from '../../../services/content.service';
import {Budget} from '../../../models/budget.model';
import { OrderService} from '../../../services/order.service';
import {Order} from '../../../models/order.model';
import {OrderWindow} from '../../../models/order-window.model';

import {getSiblings} from '../../../shared/utils';
import {ACTIVE_ORDER_TAB, HEADER_HEIGHT, LocalStorageService} from '../../../services/local-storage.service';
import {ECOMMERCE_TYPE, PREORDER_TYPE} from '../../../constants/order-types';
import {BudgetService} from '../../../services/budget.service';
import {Pages} from '../../../enums/pages.enum';
import {PaymentService} from '../../../services/payments.service';
import {ModalService} from '../../../services/modal.service';
import {PageService} from '../../../services/page.service';
import {SearchService} from '../../../services/search.service';
import {MobileCheckServiceService} from '../../../services/mobile-check-service.service';
import {MultiTenantService} from '../../../services/multi-tenant.service';
import { OnDemand } from 'app/models/on-demand.model';

export const HEADER_HEIGHT_WITH_CALLOUT = 179;
export const DESKTOP_HEADER_HEIGHT = 143;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MenuService],
    animations: [
        trigger('showInfo', [
            state('1', style({transform: 'rotate(180deg)'})),
            state('0', style({transform: 'rotate(0deg)'})),
            transition('1 => 0', animate('400ms')),
            transition('0 => 1', animate('400ms'))
        ])
    ]
})
export class HeaderComponent implements OnInit, OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    @ViewChild('stickyHeader') stickyHeader: ElementRef;

    public showInfoContent = false;
    public settings: Settings;
    public menuItems: Array<any>;
    public mobileNavActive = false;
    public showMobileNav = false;
    public isMobile = false
    public programs: Array<ProductCatalog> = [];
    public products: Array<Product> = [];
    // public user: User = new User({});
    public budgets: Array<Budget>;
    private dropdownTimer: any;
    private showMenuTimer: any;
    private limit = 4;
    private offset = 0;
    private alive = true;
    public unreadNotificationCount = 0;
    public activeEmployeeOrder: Order;
    public employeeOrders: Array<Order> = [];
    public featuredbrands: Array<any> = [];
    public orderType: string;
    public orderWindow: number;
    public activeOrderTab = 'buyingWindow';
    public activeOrderWindow: OrderWindow;

    termsContent: any;

    allPages = Pages;
    currentPage: Pages;

    private inited = false;

    showSearchInput = false;
    showSearchButton = true;

    ondemandMenuActive = false;
    buyingWindowMenuActive = false;

    mobileNavigationMenuVisible = false;
    mobileCartMenuVisible = false;

    constructor(
        // public formBuilder: FormBuilder,
        protected router: Router,
        public appSettings: AppSettings,
        protected authService: AuthService,
        public menuService: MenuService,
        protected productService: ProductService,
        private profileService: ProfileService,
        protected notificationService: NotificationService,
        public orderService: OrderService,
        private configService: ConfigService,
        private contentService: ContentService,
        private route: ActivatedRoute,
        protected toastr: ToastrService,
        private localStorageService: LocalStorageService,
        private budgetService: BudgetService,
        private paymentService: PaymentService,
        private modalService: ModalService,
        private pageService: PageService,
        private searchService: SearchService,
        private elementRef: ElementRef,
        private mobileCheck: MobileCheckServiceService,
        private multitenantService: MultiTenantService,
    ) {
        this.settings = this.appSettings.settings;
        this.menuItems = this.menuService.getHorizontalMenuItems();
    }

    ngOnInit(): void {
        this.detectPageSize();

        if (this.paymentService.paymentsMethods.length === 0) {
            this.paymentService.fetchUserPaymentMethods();
        }
        this.handleActiveOrderChange();

        this.init();
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    // wait until order service initialized
    private init(): void {
        // get clearance and truck graphics urls
        if (this.canSeeClearance) {
            this.contentService.getClearanceUrl();
        }
        if (this.canSeeTruckGraphics) {
            this.contentService.getTruckGraphicsUrl();
        }

        this.router.events.pipe(takeWhile(() => this.alive)).subscribe(event => {
            // detect current page only if orders are loaded
            if (event instanceof NavigationEnd) {
                this.setupSearch();
                if (this.inited && this.isAuthenticated) {
                    this.setupHeader();
                }
            }

        });

        this.orderService.toRx('inited').pipe(takeWhile(() => this.alive)).subscribe((init) => {
            if (init && !this.inited && this.isAuthenticated) {
                this.inited = true;

                this.setupHeader();

                this.blockUI.stop();

            }
        });

        this.orderService.fetchAllOrders();

        // this.productService.getPopularOnDemandProducts().subscribe(  () => {
        // });
        //
        // this.productService.fetchOnDemandProductCategories().subscribe( () => {
        // })
        //
        // this.productService.fetchOnDemandProductBrands().subscribe( () => {
        // })
    }

    private setupHeader(): void {
        if (this.detectCurrentPage() === true) {
            // get notifications only if page has been changed
            this.getNotifications();
        }

        // keep header height  for filters
        this.localStorageService.setItem(HEADER_HEIGHT, this.headerOffset.toString());

        this.getData();
    }

    private getNotifications(): void {
        this.notificationService.unreadCount()
            .pipe(take(1))
            .subscribe(data => {
                this.unreadNotificationCount = data;
            });
    }

    // returns true if current page has been changed
    private detectCurrentPage(): boolean {
        let result = false;
        const currentUrl: string  = this.router.url;

        let navigationPage: Pages;

        if (currentUrl.indexOf('/search-results') === 0) {
            navigationPage = Pages.Search;
        } else if ( currentUrl.indexOf('/buying-window') === 0) {
            navigationPage = Pages.BuyingWindowPage;
        } else if (currentUrl.indexOf('/on-demand') === 0) {
            navigationPage = Pages.OnDemand;
        } else if (currentUrl.indexOf('favorites') !== -1) {
            navigationPage = Pages.Favorites;
        } else if ( currentUrl === '/') {
            navigationPage = Pages.Home;
        } else {
            navigationPage = Pages.Others;
        }

        if (this.currentPage !== navigationPage) {
            result = true;

            this.currentPage = navigationPage;
            // change selected order type
            if (this.currentPage === Pages.BuyingWindowPage) {
                this.orderService.setCurrentOrderType(PREORDER_TYPE);
            } else if ( this.currentPage !== Pages.Search) {
                // set for the rest of pages ECOMMERCE_TYPE, except search result
                this.orderService.setCurrentOrderType(ECOMMERCE_TYPE);
            }
        }

        return result;
    }

    private getData(): void {
        // if (this.featuredbrands.length === 0) {
        //     this.configService.getConfigData('brands')
        //         .pipe(take(1))
        //         .subscribe(data => {
        //             this.featuredbrands = data;
        //         });
        // }
        //
        // this.productService.getEcomProducts(1, null, '3-0')
        //     .pipe(take(1))
        //     .subscribe(({products}) => {
        //         this.products = products;
        //     });
    }

    public onSelectModal(selection: string): void {
        this.localStorageService.setItem(ACTIVE_ORDER_TAB, selection);
    }

    public toggleMobileNav(): void {
        this.mobileNavActive = !this.mobileNavActive;
    }

    get headerHeight(): number {
        if (!this.stickyHeader) {
            return 0;
        }
        return this.stickyHeader.nativeElement.offsetHeight || 0;
    }


    @HostListener('window:resize')
    public onWindowResize(): void {
        setTimeout(() => {
            this.detectPageSize();
        })
    }


    private detectPageSize(): void {
        this.showMobileNav = this.mobileCheck.mobile;
        this.isMobile = this.mobileCheck.mobileDevice;
    }

    showMenu(type: string): void {
        if (this.authService.isRestricted) {
            return;
        }
        clearTimeout(this.dropdownTimer);
        this.showMenuTimer = setTimeout(() => {
            if (type === ECOMMERCE_TYPE) {
                this.ondemandMenuActive = true;
                this.buyingWindowMenuActive = false;
            } else {
                this.ondemandMenuActive = false;
                this.buyingWindowMenuActive = true;
            }
        }, 500)
    }

    hideMenu(type: string): void {
        clearTimeout(this.showMenuTimer);

        // check if menu already hidden
        if (type === ECOMMERCE_TYPE && this.ondemandMenuActive === false) {
            return;
        }

        if (type === PREORDER_TYPE && this.buyingWindowMenuActive === false) {
            return;
        }

        this.dropdownTimer = setTimeout(() => {
            type === ECOMMERCE_TYPE ? this.ondemandMenuActive = false : this.buyingWindowMenuActive = false;
        }, 200);
    }

    dropdownMouseEnter(event): void {
        clearTimeout(this.dropdownTimer);
        clearTimeout(this.showMenuTimer);
        const siblings = getSiblings(event.target);
        for (const el of siblings) {
            el.classList.remove('is-active');
        }
        event.target.classList.add('is-active');
    }

    dropdownMouseLeave(event): void {
        this.dropdownTimer = setTimeout(() => {
            event.target.classList.remove('is-active');
        }, 200);
    }

    toggleGlobalSearch(event): void {
        event.preventDefault();
        this.searchService.clearSearchResults();
        this.showSearchInput = true;

        // SearchStoreInstance.searchLabel = '';
        // SearchStoreInstance.clearSearchResults();
        // SearchStoreInstance.toggleGlobalSearch();
    }

    /**
     * it shows SAP Dialog in case of employee, otherwise order name dialog
     */
    editOrderSettings(): void {
        const order = this.orderService.activeOrder;
        this.modalService.editOrderSettings(order);
    }

    // triggers when active order window is changed
    private handleActiveOrderChange(): void {
        this.orderService.toRx('activeOrderWindow').pipe(
            takeWhile(() => this.alive)
        ).subscribe(orderWindow => {
            this.activeOrderWindow = orderWindow;

            this.getOrderWindowData(this.activeOrderWindow);

        })
    }

    // get data depend on order window
    private getOrderWindowData(orderWindow: OrderWindow): void {
        // Buying Window Programs
        if (orderWindow) {
            this.productService.getCatalogs(orderWindow.id, [], '3-0')
                .pipe(take(1))
                .subscribe(data => {
                    this.programs = data.catalogs;
                });
        }
    }

    get statistics(): any {
        return this.productService.statistics;
    }

    get canOder(): boolean {
        return this.authService.canOrder;
    }

    get canSeeFavorites(): boolean {
        return this.authService.canSeeFavorites && !this.authService.isRestricted;
    }

    get canSeeNotifications(): boolean {
        return this.authService.canSeeNotifications;
    }

    get headerCalloutVisible(): boolean {
        return this.currentPage === Pages.Home
            || (this.currentPage === Pages.BuyingWindowPage && this.orderService.currentOrderType === PREORDER_TYPE);
    }

    get headerOffset(): number {
        if (this.showMobileNav) {

            if (this.fixedHeader) {
                return this.headerHeight;
            }

            return 0;
        }

        if (!this.fixedHeader) {
            return 0;
        }

        return this.headerCalloutVisible ? HEADER_HEIGHT_WITH_CALLOUT : DESKTOP_HEADER_HEIGHT;
    }


    get fixedHeader(): boolean {
        return this.appSettings.settings.userFeatures['fixedHeader'] === true;
    }

    get isAuthenticated(): boolean {
        return this.authService.authenticated;
    }

    get onDemandOrderItemsCount(): number {
        return this.orderService.activeODOrder ? this.orderService.activeODOrder.item_count : 0;
    }

    get buyingWindowOrderItemsCount(): number {
        return this.orderService.activeBWOrder ? this.orderService.activeBWOrder.item_count : 0;
    }

    get totalOrderItems(): string {
        const bwCount = this.buyingWindowOrderItemsCount;
        const odCount = this.onDemandOrderItemsCount;
        if (odCount > 0 && bwCount > 0) {
            return  odCount + bwCount + '+';
        } else if (bwCount > 0) {
            return '' + bwCount;
        } else if (odCount > 0) {
            return '' + odCount;
        }
        return '';
    }

    navigateToAllBWPrograms() {
        this.router.navigate([`/buying-window/${this.activeOrderWindow.id}/programs`]);
        return false;
    }

    navigateToBWProducts(brand = null) {
        const path = `/buying-window/${this.activeOrderWindow.id}/products`;
        this.router.navigate([path]);
        return false;
    }

    navigateToODProducts(brand = null) {
        const path = '/on-demand/products';
        this.router.navigate([path]);
        return false;
    }

    navigateToODPrograms() {
        this.router.navigate(['/on-demand/programs']);
        return false;
    }

    navigateToActiveODOrder() {
        this.onSelectModal('onDemand');
        this.router.navigate([`/cart/${this.orderService.activeODOrder.id}`]);
        return false;
    }

    navigateToActiveBWOrder() {
        this.onSelectModal('buyingWindow');
        this.router.navigate([`/cart/${this.orderService.activeBWOrder.id }`]);
        return false;
    }

    navigateToFavorites(event: Event) {
        event.preventDefault();
        const isBWPage = this.currentPage === Pages.BuyingWindowPage ? {bw: 1} : null;
        this.router.navigate(['/favorites'], {queryParams: isBWPage});
        return false;
    }

    onSearchInputClosed(): void {
        if (this.currentPage !== Pages.Search) {
            this.showSearchInput = false;
        }
    }

    private setupSearch(): void {
        const currentUrl: string  = this.router.url;
        if (currentUrl.indexOf('search-results') !== -1) {
            this.showSearchButton = false;
            this.showSearchInput = true;
        } else {
            this.showSearchInput = false;
            this.showSearchButton = true;
        }
    }


    closeMobileNav() {
        this.mobileNavActive = false;
        return true;
    }

    get canSeeReports(): boolean {
        return this.authService.canSeeReports;
    }

    get canSeeClearance(): boolean {
        return this.authService.canSeeClearance;
    }

    get canSeeTruckGraphics(): boolean {
        return this.authService.canSeeTruckGraphics;
    }

    get canSeeBuyingWindows(): boolean {
        return this.authService.canBuyingWindow;
    }

    get onDemandType(): string {
        return ECOMMERCE_TYPE;
    }

    get buyingWindowType(): string {
        return PREORDER_TYPE;
    }

    navigateToClearance(): void {
        this.router.navigate([this.clearanceUrl]);
    }

    navigateToTruckGraphics(): void {
        this.router.navigate([this.truckGraphicsUrl]);
    }

    get clearanceUrl(): string {
        return this.contentService.clearanceUrl;
    }

    get truckGraphicsUrl(): string {
        return this.contentService.truckGraphicsUrl;
    }

    get isRestricted(): boolean {
        return this.authService.isRestricted;
    }

    get logoUrl(): string {
        return this.multitenantService.logoImageUrl;
    }

    get orderWindowLabel(): string {
        return this.multitenantService.orderWindowLabel;
    }

    get onDemandLabel(): string {
        return this.multitenantService.onDemandLabel;
    }

    get onBuyingWindowPage(): boolean {
        return this.currentPage === Pages.BuyingWindowPage && this.orderService.currentOrderType === PREORDER_TYPE
    }

    get onDemandPage(): boolean {
        return this.currentPage === Pages.OnDemand;
    }
}
