import { Component, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { CostCenter } from '../../../models/cost-center.model';
import {PaymentService} from '../../../services/payments.service';

@Component({
    selector: 'app-sap-account',
    templateUrl: './sap-account.component.html',
    styleUrls: ['./sap-account.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class  SapAccountComponent {

    costCenter: CostCenter;

    closeWithWarning = false;

    constructor(private activeModal: NgbActiveModal, private paymentService: PaymentService) {}


    onVerifiedSap(costCenter: CostCenter) {
        if (costCenter) {
            this.paymentService.saveSapAccount(costCenter).then( (result) => {
                this.close(result);
            })
        } else {
            this.close();
        }
    }

    close(costCenter?: CostCenter) {
        this.activeModal.close(costCenter);
    }
}
