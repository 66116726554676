import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AddressState } from '../reducers/address.reducer';

export const selectAddressState =
  createFeatureSelector<AddressState>('address');

export const fetchUserDefaultAddress = createSelector(
  selectAddressState,
  (state: AddressState) => state.userDefaultAddress
);
export const fetchAddress = createSelector(
  selectAddressState,
  (state: AddressState) => state.address
);
