<ng-container *ngIf="!wslrEntity && !isDecisionPoint; else shipmentLabel">
  <ng-select
    #select
    class="ng-select--custom searchable address--selector"
    [ngClass]="dropDownClasses"
    [items]="computedAddressList"
    bindValue="id"
    placeholder="Ship-to address"
    [searchable]="true"
    [loading]="addressesLoading"
    [typeahead]="addressesSubject"
    (change)="onSelect($event)"
    [clearable]="false"
    (close)="onCloseDropdown()"
    [disabled]="disabled"
    dropdownPosition="bottom"
    [(ngModel)]="addr_id"
  >
    <ng-template ng-label-tmp let-item="item">
      <ng-container *ngIf="delivery.addressDefined">
        <span class="d-block font-weight-bold font-5">{{ addressLabel }}</span>
        <span>{{ delivery.street_1 }}, {{ delivery.getCSZ() }}</span>
      </ng-container>
    </ng-template>
    <ng-template ng-header-tmp>
      <span class="font-6">{{ itemsTemplateHeader }}:</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div
        class="address-list-title font-weight-bold text-underline color-black font-6"
      >
        <ng-container
          *ngIf="
            item.isFirstItem && item.address_type === addressType.Corporate
          "
        >
          Corporate
        </ng-container>
        <ng-container
          *ngIf="item.isFirstItem && item.address_type === addressType.Personal"
          >Personal</ng-container
        >
      </div>
      <span class="font-weight-bold font-6">
        <ng-container *ngIf="item.is_default">
          <i class="fas fa-star color-gold"></i>&nbsp;
        </ng-container>
        {{ item.label }}</span
      ><br />
      <span class="font-6">{{ item.longAddress }}</span>
    </ng-template>
    <ng-template ng-footer-tmp>
      <a
        *ngIf="canAddAddresses"
        href="#"
        class="d-block text-align-center my-2 link font-5 link-underline"
        (click)="addAddress()"
        >Add New Address&nbsp;<i class="fas fa-plus"></i
      ></a>
    </ng-template>
  </ng-select>
</ng-container>

<ng-template #shipmentLabel>
  <app-shipping-label
    [delivery]="delivery"
    [isDecisionPoint]="isDecisionPoint"
    [wslrEntity]="_entity"
    [attention]="attention"
  ></app-shipping-label>
</ng-template>
