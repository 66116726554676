import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {Product} from '../../../models/product.model';
import { SKU } from '../../../models/sku.model';
import {isEmptyArray} from '../../../shared/utils';
import {CurrencyService, PriceFormatOptions} from '../../../services/currency.service';
import { Currency } from '../../../interfaces/currency';


interface IPriceTiers {
    range: string;
    price: number;
    currency?: Currency;
    selected: boolean;
    packageType: string;
}

interface IRunOnPrice {
    quantity: number;
    pricing: number;
    currency?: Currency;
    each_additional: number;
    selected: boolean;
    packageType: string;
}

const TIER_WINDOW_WIDTH = 200;
const RANGE_WINDOW_WIDTH = 240;

const MAX_VISIBLE_TIERS = 10;

@Component({
    selector: 'app-pricing-tiers',
    templateUrl: './price-tiers.component.html',
    styleUrls: ['./price-tiers.component.scss'],
//    encapsulation: ViewEncapsulation.None
})
export class PriceTiersComponent implements OnInit, AfterViewInit {

    _quantity = 0;

    priceTiers: Array<IPriceTiers> = [];
    pricesRunOnRange: Array<IRunOnPrice> = [];

    placementPosition: string;

    @Input() product: Product;

    @Input() sku: SKU;

    @Input() showAsTable = false;

    @Input() modal = false;

    @Input()
    set quantity(value: number) {
        this._quantity = value || 0;
        this.priceTiers = this.computePriceTiers(this.product, this._quantity);
    }

    constructor(private elementRef: ElementRef, private currencyService: CurrencyService) {
    }


    ngOnInit(): void {
        this.priceTiers = this.computePriceTiers(this.product, this._quantity);
    }

    ngAfterViewInit(): void {
        this.setDropdownPlacement();
    }


    @HostListener('window:resize')
    private onResize() {
        this.setDropdownPlacement();
    }


    private setDropdownPlacement() {
        // check if modal window is open then get size of modal window
        const modalWindow = document.querySelector('app-order-item-modal');
        let windowSize = 0;
        if (modalWindow) {
            windowSize = modalWindow.clientWidth;
        }
        if (!windowSize) {
            windowSize = window.innerWidth;
        }
        const div = this.elementRef.nativeElement.querySelector('.dropdown-toggle') as HTMLDivElement | null;
        if (div) {
            const {x} = div.getBoundingClientRect();
            setTimeout(() => {
                if (this.modal) {
                    this.placementPosition = 'bottom-right';
                } else {
                    this.placementPosition = (windowSize < x + this.windowSize) ? 'bottom-right' : 'bottom-left';
                }

            });
        }
    }

    private get windowSize(): number {
        return this.hasPriceRunOnRange ? RANGE_WINDOW_WIDTH : TIER_WINDOW_WIDTH;
    }

    private setPriceTierVisible() {
        const div = this.elementRef.nativeElement.querySelector('.dropdown-menu-content.pricing-tiers') as HTMLDivElement | null;
        if (!div) {
            return;
        }


        if (
          (isEmptyArray(this.priceTiers) || this.priceTiers?.length < MAX_VISIBLE_TIERS) &&
          (isEmptyArray(this.pricesRunOnRange) || this.pricesRunOnRange?.length < MAX_VISIBLE_TIERS)) {
            return;
        }

        // no need to scroll if visible lower than MAX_VISIBLE_TIERS
        let selectedPriceIdx = -1;
        if (this.priceTiers?.length > 0) {
            selectedPriceIdx = this.priceTiers.findIndex(p => p.selected);
        } else if (this.pricesRunOnRange?.length > 0) {
            selectedPriceIdx = this.pricesRunOnRange.findIndex(p => p.selected);
        }


        if (selectedPriceIdx < MAX_VISIBLE_TIERS - 1) {
            return;
        }


        const rootSelector = this.hasPricingTiers ? 'ul' : 'table';
        const selectedElement = this.hasPricingTiers ? 'li.is-bold' : 'tr.is-bold';

        // get selected  prices element
        const ul = div.querySelector(rootSelector) as HTMLUListElement;
        if (!ul) {
            return;
        }

        // get selected price element
        const priceLi = ul.querySelector(selectedElement) as HTMLLIElement;
        if (!priceLi) {
            return;
        }

        // add some offset for correct scrolling
        if (this.hasPricingTiers) {
            const offset = Math.max(priceLi.offsetTop - 35, 0);
            ul.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        } else {
            priceLi.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        }
        // ul.scrollTop = priceLi.offsetTop;
    }

    private computePriceTiers(product: Product, quantity: number) {
        // reset price tiers
        this.priceTiers = [];
        this.pricesRunOnRange = [];


        if (product.hasPriceRunOnRange) {
            this.pricesRunOnRange =  this.currentSKU.getPriceTiers().map( p => {
                  return {
                      quantity: p.quantity,
                      pricing: this.checkPriceType(p.pricing),
                      each_additional:  this.checkPriceType(p.each_additional),
                      selected: false,
                      currency: product.currency,
                      packageType: this.product.getUOM()
                  };
              }
            );

            this.findSelectedTier(this.pricesRunOnRange, quantity);

            return;
        }

        const result: Array< IPriceTiers> = [];
        if (!product) {
            return result;
        }

        const tiers = this.currentSKU.getPriceTiers();
        if (tiers.length < 2) {
            return result;
        }

        for ( let i = 0; i < tiers.length; i++) {
            let range: string;
            let selected = false;
            const value = tiers[i].value;
            const key = parseInt(tiers[i].key, 10);
            const alreadySelected = result.some( p => p.selected);
            // const {key, value} = tiers[i];
            if (i === 0) {
                range = `< ${key}`;
                selected = quantity < key;
            } else if ( i < tiers.length - 1) {
                const previousTier = tiers[i - 1];
                const from = parseInt(previousTier.key, 10);
                const to = key - 1;
                if (from !== to) {
                    range = ( from !== to ) ? `${from} - ${to}` : `${to}`;
                    selected = !alreadySelected  && (quantity >= from && quantity <= to);
                } else {
                    range =  `${to}`;
                    selected = !alreadySelected && (quantity === from);
                }
            } else {
                const previousTier = tiers[i - 1];
                range = `>= ${previousTier.key}`;
                selected = quantity >=  parseInt(previousTier.key, 10);
            }
            result.push({ range, price: this.checkPriceType(value), selected, packageType: product.getUOM(),
                currency: product.currency});
        }
        return this.priceTiers = result;
    }

    // private getFormattedPrice(value: string | number): string {
    //     return this.currencyService.getFormattedPrice(value, {currency: this.product.currency});
    // }


    private checkPriceType(value: string | number): number {
        if (value === undefined || value === null) {
            return 0;
        }

        if (typeof value === 'string') {
            return parseFloat(value);
        }

        return value;
    }

    get currentSKU(): SKU {
        return this.sku ? this.sku : this.product.skus[0];
    }

    checkDropDown(event, data) {
        if (event === true) {
            this.setPriceTierVisible();
        }
    }

    get hasScroll(): boolean {
        return this.priceTiers?.length > MAX_VISIBLE_TIERS || this.pricesRunOnRange?.length > MAX_VISIBLE_TIERS;
    }

    get hasPricingTiers(): boolean {
        if (!this.currentSKU) {
            return false;
        }
        return this.currentSKU.hasPricingTiers;
    }

    get hasPriceRunOnRange(): boolean {
        if (!this.currentSKU) {
            return false;
        }
        return this.currentSKU.hasPriceRunOnRange;
    }


    get showTiers(): boolean {
        if (!this.product || !this.currentSKU) {
            return false;
        }
        if (this.hasPricingTiers) {
            return !isEmptyArray(this.priceTiers);
        } else {
            return !isEmptyArray(this.pricesRunOnRange);
        }
    }


    get minWidthStyle(): any {
        return { 'width': `${this.windowSize}px` };
    }


    findSelectedTier(runOnRange: IRunOnPrice[], quantity: number) {
        for (let i = runOnRange.length - 1; i >= 0; i--) {
            if (quantity >= runOnRange[i].quantity) {
                runOnRange[i].selected =  true;
                return;
            }

            // const isNotLast = i < runOnRange.length - 1;
            // if (isNotLast) {
            //     if (quantity > runOnRange[i].quantity && quantity < runOnRange[i + 1].quantity) {
            //         runOnRange[i].selected = true;
            //         return;
            //     }
            // }

            if (i === 0) {
                // select first tier by default
                runOnRange[i].selected = true;
                return;
            }
        }
    }
}


// purchase opitiosn - icon on photo
