import { BaseModel } from './base.model';
import { AppDate } from './date.model';
import { Attribute } from './attribute.model';
import {generateUUID, normalizeTrackingData} from '../shared/utils';
import { TrackingNumber } from '../interfaces/tracking-number';
import { Customization } from '../interfaces/customization';
import { Address } from './address.model';
import { User } from './user.model';
import {CostCenter} from './cost-center.model';
import { AddressType } from 'app/enums/address-type';

export const IMS_BTI = 'IMS - BTI';

export const WSLR = 'WSLR'

export class OrderItemDelivery extends BaseModel {
    public id: number;
    public item_id: number;
    public addr_id: number;
    public cache_id: number;
    public entity_id: number;
    public status: string;
    public type: string;
    public method: string;
    public quantity: number;
    public weight: number;
    public price: number;
    public label: string;
    public street_1: string;
    public street_2: string;
    public city: string;
    public state: string;
    public zip_code: string;
    public country_code: string;
    public residential: boolean;
    public tracking_number: any;
    public shipped_at: AppDate;
    public data: any;

    public attrs: Array<Attribute>;
    public addr_type: string;
    public address_type: AddressType;

    // derived properties
    public method_formatted: string;
    public tracking_url: string;
    public formattedTracking: string;
    public unchanged = true;
    public customizations: Customization[];
    public deliver_to: string;
    public orderAttention: string;
    public cost_center: CostCenter;
    public uuid: string;
    public is_uneligible: boolean;
    // pendingDelete = false; //  true when user removed a delivery

    public internal_order: string;
    public wbs: string;

    constructor(data: any = {}) {
        super(data, {
            num: ['id', 'item_id', 'cache_id', 'entity_id', 'quantity', 'weight', 'price'],
            bool: ['residential'],
            date: ['shipped_at']
        });

        this.data = {
            addr_id : this.addr_id,
            addr_type: this.addr_type,
            entity_id: this.entity_id
        };
        if (data.data && data.data.addr_id) {
            this.data = {
                addr_id : data.data.addr_id,
                addr_type: data.data.addr_type,
                entity_id: data.data.entity_id
            };
        }


        if (data.cost_center) {
            this.cost_center = new CostCenter(data.cost_center);
        }

        this.uuid = data.uuid || generateUUID();

        if (!this.deliver_to && this.isIMS) {
            this.deliver_to = this.IMSAttention;
        }

        if (data.is_uneligible) {
            this.is_uneligible = !!data.is_uneligible;
        } else {
            this.is_uneligible = false;
        }


        if (this.label?.startsWith(WSLR)) {
            const pos = this.label.indexOf('-');
            if (pos !== -1) {
                this.label = this.label.substring(pos + 1);
            }
        }

        this.internal_order = data?.internal_order || '';
        this.wbs = data?.wbs || '';
    }

    public getCSZ() {
        let result = this.city;
        if (this.state) {
            result += ', ' + this.state;
        }
        if (this.zip_code) {
            result += ', ' + this.zip_code;
        }
        return result;
    }

    get normalizedTrackingNumbers(): TrackingNumber[] {
        return normalizeTrackingData(this.tracking_number);
    }


    get hasCustomization(): boolean {
        return Array.isArray(this.customizations);
    }

    public setAddress(
        address: Address,
        currentUser: User,
        isAutoship: boolean = false,
        userRoles: { canOrder: boolean; isEmployee: boolean }
      ) {
        if (!userRoles.canOrder) {
          return;
        }

        this.addr_id = address.id;
        this.cache_id = address.id;
        this.label = address.label;
        this.street_1 = address.street_1;
        this.city = address.city;
        this.state = address.state;
        this.country_code = address.country_code;
        this.zip_code = address.zip_code;
        this.addr_type = address.type;
        this.address_type = address.address_type;
        this.data.addr_id = address.id;
        this.data.addr_type = address.type;

        if (isAutoship && address.iShippingLocation) {
          this.data.entity_id = address.entity_id;
        } else {
            if (userRoles.isEmployee) {
                this.data.entity_id =
                address.iShippingLocation &&
                currentUser.entity_id !== address.entity_id
                    ? address.entity_id
                    : 0;
            }
        }
    }

    public getAddress(): Address {
        return new Address({
            id: this.addr_id,
            label: this.label,
            street_1: this.street_1,
            city: this.city,
            state: this.state,
            country_code: this.country_code,
            zip_code: this.zip_code,
            type: this.addr_type,
            entity_id: this.data.entity_id
        });
    }

    public prepareToSave(): object {
        const obj = {
              id: this.id,
              type: this.type,
              method: this.method,
              quantity: this.quantity,
              addr_id: this.addr_id,
              address_type: this.address_type,
              deliver_to: this.deliver_to || '',
              cost_center: this.cost_center ? this.cost_center : null,
              is_uneligible: this.is_uneligible ? 1 : 0,
              data: {
                  addr_id: this.data.addr_id || this.addr_id,
                  addr_type: this.data.addr_type,
                  entity_id: this.data.entity_id,
                  customizations: this.customizations,
              },
          }
          if (this.internal_order) {
              obj['internal_order'] = this.internal_order.trim();
          }
          if (this.wbs) {
              obj['wbs'] = this.wbs.trim();
          }

          // in some case  address_type is not set , this is  very temporary solution and can be stooped working after address book merge
          if (!obj['address_type']) {
              obj['address_type'] = obj.data.addr_type === 'OTHER_ADDR' ? 1 : 2
          }

        return obj;
    }

    get isInternational(): boolean {
        return this.country_code && this.country_code.toLowerCase() !== 'us';
    }

    get addressDefined(): boolean {
        return this.addr_id >= 0;   // for imported addresses addr_id can be 0
    }

    get isIMS(): boolean {
        return this.label && this.label.includes(IMS_BTI);
    }

    get isWSLR(): boolean {
        return this.label && this.label.indexOf(WSLR) === 0;
    }

    get IMSAttention() {
        if (!this.label) {
            return '';
        }
        return this.label;
    }

    get wslrLabel() {
        if (!this.isWSLR) {
            return this.label;
        }

        return  this.labelWithoutWslrPrefix;
    }

    get labelWithoutWslrPrefix(): string {
        if (!this.label) {
            return '';
        }
        if (this.label.startsWith('WSLR')) {
            const pos = this.label.indexOf('-');
            if (pos !== -1) {
                return this.label.substring(pos + 1);
            }
        }

        return  this.label;
    }


    get internalOrder(): string {
        return this.wbs || this.internal_order || '';
    }


}
