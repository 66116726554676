import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { Address } from '../../../models/address.model';
import { AddressService } from '../../../services/address.service';
import { AuthService } from '../../../services/auth.service';
import { OrderItemDelivery } from '../../../models/order-item-delivery.model';
import { Entity } from '../../../models/entity.model';
import { ModalService } from '../../../services/modal.service';
import {
  computeAvailableAddresses,
  computeShipmentLabel,
  flatternSortedAddressesByWslr,
} from '../../../shared/helpers';
import { OrderItem } from '../../../models/order-item.model';
import { Order } from '../../../models/order.model';
import { Product } from '../../../models/product.model';
import { MobileCheckServiceService } from '../../../services/mobile-check-service.service';
import {
  loadDefaultAddresses,
  loadSearchAddresses,
} from 'app/store/actions/address.actions';
import {
  fetchAddress,
  fetchUserDefaultAddress,
} from 'app/store/selectors/address.selectors';
import { AddressType } from 'app/enums/address-type';

const DROPDOWN_WIDTH = 300;

@Component({
  selector: 'app-ship-to-address',
  templateUrl: './ship-to-address.component.html',
  styleUrls: ['./ship-to-address.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShipToAddressComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  // private _selectedAddresses: string[] = [];
  // private searchSubscription: Subscription;
  private accountAddresses: Address[] = [];
  private alive = true;

  addr_id: number;
  addressesSubject: Subject<string> = new Subject<string>();
  addressesLoading = false;
  computedAddressList: Address[] = [];
  itemsTemplateHeader = '';
  addressType = AddressType;

  private initialized;
  private _orderItem: OrderItem;

  @ViewChild('select', { static: false }) addressSelector;

  @Input() disabled = false;

  @Input() delivery: OrderItemDelivery;

  @Input() isInline = false;

  @Input() className = '';

  _entity: Entity;
  entityLabel: string;

  // output event when address has been selected
  @Output() addressSelected = new EventEmitter<Address>();

  @Input() order: Order;

  @Input() set orderItem(val: OrderItem) {
    this._orderItem = val;
    // this.accountAddresses = computeAvailableAddresses(this.addressService.addresses,
    //     this.order,
    //     this._orderItem,
    //     this.isInline ? this.delivery : null);
    // this.computeAddressList(this.accountAddresses);
  }

  // // already selected  addresses in other deliveries
  // @Input() set selectedAddresses( val: string[]) {
  //     this._selectedAddresses = val;
  //     this.computeAddressList(this.accountAddresses, false);
  // };
  //
  // @Input() set availableAddresses(val: Address[]) {
  //     this.accountAddresses = (val && val.length) > 0 ? val : this.addressService.addresses;
  //     this.computeAddressList(this.accountAddresses, false);
  // };

  constructor(
    private addressService: AddressService,
    private authService: AuthService,
    private modalService: ModalService,
    private mobileCheck: MobileCheckServiceService,
    private store: Store<{ userDefaultAddress: Address[]; address: Address[] }>
  ) {
    this.store.pipe(select(fetchUserDefaultAddress)).subscribe((addresses) => {
      this.accountAddresses = addresses;
      this.computeAddressList(this.accountAddresses);
    });

    this.store.pipe(select(fetchAddress)).subscribe((addresses) => {
      this.addressesLoading = false;
      this.computeAddressList(addresses || [], true);
    });

    this.store.dispatch(loadDefaultAddresses({ userDefaultAddress: this.accountAddresses }));
  }

  ngOnInit(): void {
    this.addressesSubject
      .pipe(
        takeWhile(() => this.alive),
        debounceTime(200),
        distinctUntilChanged(),
        tap((term) => {
          this.addressesLoading = true;

          this.store.dispatch(
            loadSearchAddresses({
              term,
              productId: this.productId,
              isEmployee: this.isEmployee,
              accountAddresses: this.accountAddresses,
            })
          );
        })
      )
      .subscribe();

    if (this.delivery) {
      this.addr_id = this.delivery.addr_id;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.initialized = true));
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onSelect(data): void {
    if (data instanceof Event) {
      return;
    }
    this.addressSelected.emit(data);
  }

  onCloseDropdown(): void {
    // reset search items to default state
    this.computeAddressList(this.accountAddresses, false);
  }

  computeAddressList(addresses: Address[] = [], isWholesalerAddresses = false) {
    if (this.isEmployee) {
      this.itemsTemplateHeader = isWholesalerAddresses
        ? 'Wholesalers Addresses'
        : 'Your Addresses';
    } else {
      this.itemsTemplateHeader = 'Your Addresses';
    }

    this.computedAddressList = flatternSortedAddressesByWslr(
      [...addresses],
      this.authService.user.entity_id
    );

    if (this.delivery) {
      this.addr_id = this.delivery.addr_id;
    } else {
      if (this.computedAddressList.length > 0) {
        this.addr_id = this.computedAddressList[0].id;
      } else {
        this.addr_id = null;
      }
    }
  }

  getAddressInfo(delivery: OrderItemDelivery): string {
    if (!delivery) {
      return '';
    }

    return `${delivery.street_1}, ${delivery.getCSZ()}`;
  }

  addAddress() {
    this.modalService.editAddress(null, true).then((result) => {
      if (result) {
        this.accountAddresses = computeAvailableAddresses(
          this.addressService.addresses,
          this.order,
          this._orderItem,
          this.delivery
        );
        this.computeAddressList(this.accountAddresses);
        this.addr_id = result.id;
        this.addressSelected.emit(result);
      }
    });
    this.addressSelector.close();
    return false;
  }

  get addressLabel() {
    if (!this.delivery) {
      return '';
    }

    return computeShipmentLabel(this.delivery, this.attention);
  }

  get product(): Product {
    return this._orderItem?.product;
  }

  get productId(): number {
    return this.product?.id;
  }

  get isEmployee(): boolean {
    return this.authService.isEmployee;
  }

  get isAutoship(): boolean {
    return this.order.isCustom;
  }

  get isDecisionPoint(): boolean {
    return this.order.isDecisionPoint;
  }

  get attention(): string {
    return this.order?.attr_attention || '';
  }

  get wslrEntity(): Entity {
    return this.order.isWSLR ? this.order?.entity : null;
  }

  get deliveries(): OrderItemDelivery[] {
    return this._orderItem?.deliveries || [];
  }

  get canAddAddresses(): boolean {
    return this.authService.canAddAddress;
  }

  get mobile(): boolean {
    return this.mobileCheck.mobile;
  }

  get dropDownClasses(): string {
    if (!this.mobile || !this.initialized) {
      return `${this.className}`;
    }

    let leftAligned = false;
    if (this.addressSelector) {
      const rect = this.addressSelector.element.getBoundingClientRect();
      leftAligned = rect.right >= DROPDOWN_WIDTH;
    }

    return `${this.className} ${this.mobile ? 'mobile ' : 0} ${
      leftAligned ? 'left-aligned' : ''
    }`;
  }
}
