import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Address } from '../../../../models/address.model';
import { flatternSortedAddressesByWslr } from '../../../../shared/helpers';
import { AuthService } from '../../../../services/auth.service';
import { ModalService } from '../../../../services/modal.service';
import { MobileCheckServiceService } from '../../../../services/mobile-check-service.service';
import { Store, select } from '@ngrx/store';
import { fetchAddress, fetchUserDefaultAddress } from 'app/store/selectors/address.selectors';
import { loadDefaultAddresses, loadSearchAddresses } from 'app/store/actions/address.actions';
import { AddressType } from 'app/enums/address-type';

const DROPDOWN_WIDTH = 300;

@Component({
  selector: 'app-preset-address-item',
  templateUrl: './preset-address-item.component.html',
  styleUrls: ['./preset-address-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PresetAddressItemComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() address: Address;
  @Input() showAddButton = false;
  @Input() disabled = false;

  private accountAddresses: Address[] = [];
  private alive = true;

  addr_id: number;
  addressesSubject: Subject<string> = new Subject<string>();
  addressesLoading = false;
  computedAddressList: Address[] = [];
  selectedAddress: Address;
  itemsTemplateHeader = 'Your Addresses';
  addressType = AddressType;

  private initialized;

  @ViewChild('select', { static: false }) addressSelector;

  @Input() className = '';

  // output event when address has been selected
  @Output() addressUpdated = new EventEmitter<Address>();
  @Output() addressRemoved = new EventEmitter<void>();
  @Output() anotherAddressAdded = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private mobileCheck: MobileCheckServiceService,
    private store: Store<{ userDefaultAddress: Address[]; address: Address[] }>
  ) {
    this.store.pipe(select(fetchAddress)).subscribe((addresses) => {
      this.addressesLoading = false;
      this.computeAddressList(addresses);
    });

    this.store.pipe(select(fetchUserDefaultAddress)).subscribe((addresses) => {
      this.accountAddresses = addresses;
      this.computeAddressList(this.accountAddresses);
    });

    this.store.dispatch(loadDefaultAddresses({ userDefaultAddress: this.accountAddresses }));
  }

  ngOnInit(): void {
    this.addressesSubject
      .pipe(
        takeWhile(() => this.alive),
        debounceTime(200),
        distinctUntilChanged(),
        tap((term) => {
          this.addressesLoading = true;

          this.store.dispatch(
            loadSearchAddresses({
              term,
              productId: null,
              isEmployee: this.isEmployee,
              accountAddresses: this.accountAddresses,
            })
          );
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.address && changes.address.currentValue) {
      this.computeAddressList(this.accountAddresses);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.initialized = true));
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onSelect(data: Address | Event): void {
    if (data instanceof Event) {
      return;
    }
    this.selectedAddress = data 
    this.addressUpdated.emit(this.selectedAddress);
  }

  onCloseDropdown(): void {
    // reset search items to default state
    this.computeAddressList(this.accountAddresses);
  }

  computeAddressList(addresses: Address[] = []): void {
    this.computedAddressList = flatternSortedAddressesByWslr(
      [...addresses],
      this.authService.user.entity_id
    );

    this.selectedAddress = this.address;

    if (!this.selectedAddress && this.computedAddressList.length > 0) {
      this.selectedAddress = this.computedAddressList[0];
    }

    this.addr_id = this.selectedAddress?.id || null;
  }

  // get addressLabel() {
  //   if (!this.delivery) {
  //     return '';
  //   }
  //
  //   return computeShipmentLabel(this.delivery, this.attention);
  // }

  onAddAddress() {
    this.modalService.editAddress(null, true).then((result) => {
      if (result) {
        this.computeAddressList(this.accountAddresses);
        this.addr_id = result.id;
        this.addressUpdated.emit(result);
      }
    });
    this.addressSelector.close();
    return false;
  }

  onRemoveAddress(): void {
    this.addressRemoved.emit();
  }

  onAddAnotherAddress(): void {
    this.anotherAddressAdded.emit();
  }

  get canAddAddresses(): boolean {
    return this.authService.canAddAddress;
  }

  get isEmployee(): boolean {
    return this.authService.isEmployee;
  }

  get mobile(): boolean {
    return this.mobileCheck.mobile;
  }

  get dropDownClasses(): string {
    if (!this.mobile || !this.initialized) {
      return `${this.className}`;
    }

    let leftAligned = false;
    if (this.addressSelector) {
      const rect = this.addressSelector.element.getBoundingClientRect();
      leftAligned = rect.right >= DROPDOWN_WIDTH;
    }

    return `${this.className} ${this.mobile ? 'mobile ' : 0} ${
      leftAligned ? 'left-aligned' : ''
    }`;
  }
}
